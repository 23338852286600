<template>
  <div class="view pa24">
    <div class="flex-a-c bgf5f6 pall10">
      <p class="fs8 fwbold cA1 mr20 w100">转介绍模式</p>
      <el-switch v-model="state" class="mt3 mr20" active-color="#51CBCD" inactive-color="#C0CBCC">
      </el-switch>
      <p class="fs7 cA1" style="width: calc(100% - 140px);">
        开启后，企业销售成员可邀请其他用户成为下级合作伙伴。下级合作伙伴可向销售分享介绍更多客户线索，销售与介绍的客户成交后，合作伙伴则可获得相应推广赏金。</p>
    </div>
    <div class="flex-a-c h40 pl10 mt10">
      <p class="fs8 fwbold cA1 mr20 w100">转介绍模式</p>
      <p class="fs7 cA1" style="width: calc(100% - 100px);">注：如某一用户即为企业的"<span class="cred">合作伙伴</span>"，又为企业的“<span
          class="cred">分销员</span>”，当他推荐的客户在<span class="cred">商城下单“分销商品”</span>后，此用户只可获得“<span
          class="cred">分销佣金</span>”奖励，不可重复获得“<span class="cred">推广赏金</span>”。</p>
    </div>
    <el-input class="pl10 mt10 w100p" type="textarea" :rows="4" placeholder="为避免与用户产生纠纷，请写明详细转介绍活动规则" v-model="desc">
    </el-input>
    <div class="pl10 mt10 fs8 fwbold cA1">
      推广赏金设置
    </div>
    <div class="pl10 mt10 fs7 cA1">
      <span>
        推广赏金设置：合作伙伴介绍的客户和他的上级专属伙伴发生交易（商城订单为"已完成"状态）
      </span>
      <el-input class="w80 mr10" v-model="settlementDate" type="number" @blur="Blurtext($event)">
      </el-input>
      <span>天后，则可获得成交金额</span>
      <el-input class="w80 mr5 ml10" v-model="rate" type="number" @blur="percentText($event)">
      </el-input>
      <span>
        %的推广赏金。 (第一内容填0表示不受限制)。
      </span>
    </div>

    <div class="pl10 mt10 fs7 cA1">
      提现金额设置：赏金满
      <el-input class="w80 mr10 ml10" v-model="cashAmount" @change="() => {
        cashAmount =  cashAmount < 0 ? '' : cashAmount
      }">
      </el-input>
      元，则可提现 (填0表示不受限制)
    </div>
    <div class="pl10 mt18 fs7 cA1">
      自动提现设置：
      <el-switch v-model="autoArrive" class="mt3 mr20" active-color="#51CBCD" inactive-color="#C0CBCC">
      </el-switch>
      开启后，提现的赏金无需人工审核，自动通过。
    </div>
    <div class="flex-c-c mt40">
      <el-button type="primary" @click="saveSetting">保存</el-button>
    </div>

  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  updateSetting,
  querySetting
} from "@/api/turnToIntroduce";

export default {
  name: "visitorData",
  components: {

  },
  data() {
    return {
      state: false,
      desc: '',
      settlementDate: 0,
      rate: 0,
      cashAmount: 0,
      autoArrive: false,
    };
  },
  mounted() {
    this.querySetting()
  },
  methods: {
    saveSetting() {
      if (!this.desc && this.state) {
        this.$message.error("请先填写转介绍规则")
        return
      }
      let data = {
        rate: this.rate / 100,
        autoArrive: this.autoArrive,
        settlementDate: this.settlementDate,
        cashAmount: this.cashAmount,
        state: this.state ? 1 : 0,
        desc: this.desc
      }
      updateSetting(data)
        .then(res => {
          if (res.code == 200) {
            this.$message.success('保存成功')
          }
        })
    },
    querySetting() {
      let data = {
        ignore: true
      }
      querySetting(data)
        .then(res => {
          //-console.log(res.data,111111)
          if (res.data) {
            //-console.log(res.data,22222222)
            this.rate = res.data.rate * 100
            this.autoArrive = res.data.autoArrive
            this.settlementDate = res.data.settlementDate
            this.cashAmount = res.data.cashAmount
            this.state = res.data.state
            this.desc = res.data.desc
          }
        })
    },
    Blurtext(e) {
      let boolean = new RegExp("^[0-9][0-9]*$").test(e.target.value)
      if (!boolean) {
        this.$message.warning("请输入正整数")
        e.target.value = ''
      } else if (e.target.value > 999) {
        this.$message.warning("天数最大值为999")
        e.target.value = ''
      }
    },
    percentText(e) {
      if (e.target.value < 0 || e.target.value > 100) {
        e.target.value = 0
      } else {
        e.target.value = (e.target.value * 1).toFixed(2)
      }
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
